// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { createAssertionFunction } from '../errors';
export var I18nErrorCode;
(function (I18nErrorCode) {
    I18nErrorCode["NotConfigured"] = "NotConfigured";
})(I18nErrorCode || (I18nErrorCode = {}));
const i18nErrorMap = {
    [I18nErrorCode.NotConfigured]: {
        message: 'i18n is not configured.',
    },
};
export const assert = createAssertionFunction(i18nErrorMap);
